export enum IconName {
  AadGroup = "aad-group",
  AarFile = "aar-file",
  AccountSettings = "account-settings",
  Add = "add",
  AddCircle = "add-circle",
  AddUser = "add-user",
  Admin = "admin",
  AppAnalytics = "app-analytics",
  AppBack = "app-back",
  AppBeacon = "app-beacon",
  AppBuild = "app-build",
  AppCrashes = "app-crashes",
  AppCenter = "appcenter",
  AppCenterWhite = "appcenter-white",
  AppData = "app-data",
  AppDistribute = "app-distribute",
  AppDocs = "app-docs",
  AppHome = "app-home",
  AppAuth = "app-auth",
  AppIndex = "app-index",
  AppOverview = "app-overview",
  AppleStore = "apple-store",
  AppleIcon = "apple-icon",
  AppleIconInvert = "apple-icon-invert",
  AppleTestFlightStore = "apple-test-flight",
  ApplicationInsights = "application-insights",
  ArrowsDown = "arrows-down",
  AuthBeacon = "auth-beacon",
  AppSettings = "cog",
  AppSupport = "app-support",
  AppTest = "app-test",
  AttachmentBinary = "binary-attachment",
  AttachmentText = "text-attachment",
  Auth0 = "auth0",
  Azure = "azure",
  Aad = "aad",
  AzureSubscription = "azure-subscription",
  AzureSubscriptionDisabled = "azure-subscription-disabled",
  Billing = "billing",
  Bitbucket = "bitbucket",
  Bluetooth = "bluetooth",
  Breadcrumb = "breadcrumb",
  Bullet = "bullet",
  ButtonChevronLeft = "button-chevron-left",
  ButtonChevronRight = "button-chevron-right",
  ButtonExpandLess = "button-expand-less",
  ButtonExpandMore = "button-expand-more",
  Calendar = "calendar",
  CalendarClock = "calendar-clock",
  Certificate = "certificate",
  Check = "check",
  ChevronLeft = "chevron-left",
  ChevronRight = "chevron-right",
  Clear = "clear",
  Close = "close",
  CloseSmall = "close-small",
  Code = "code",
  CosmosDB = "cosmos-db",
  Copy = "copy",
  Crash = "crash",
  CrashFilled = "crash-filled",
  CsrFile = "csr-file",
  CsvFile = "csv-file",
  Default = "default",
  Delete = "delete",
  Desktop = "desktop",
  DeviceOrientation = "device-orientation",
  DistributionGroup = "distribution-group",
  Docs = "docs",
  DocsEdit = "docs-edit",
  Done = "done",
  Download = "download",
  DoubleChevronLeft = "double-chevron-left",
  DoubleChevronRight = "double-chevron-right",
  Edit = "edit",
  Email = "email",
  EmailAdd = "email-add",
  EmailReply = "email-reply",
  ExpandLess = "expand-less",
  ExpandMore = "expand-more",
  FieldClear = "status-failed",
  Facebook = "facebook-box",
  FacebookInvert = "facebook-invert",
  File = "file",
  FilterList = "filter-list",
  FilterAdd = "filter-add",
  Filter = "filter",
  Firebase = "firebase",
  Fork = "fork",
  GettingStarted = "getting-started",
  Git = "git",
  GitHub = "github",
  GitLab = "gitlab",
  Google = "google",
  GooglePlay = "google-play",
  Group = "group",
  GroupCircle = "group-circle",
  Help = "help",
  Info = "info",
  InfoFilled = "info-filled",
  InstallOnDevice = "install-on-device",
  IntuneCompanyPortal = "intune-company-portal",
  Issue = "issue",
  IssuesCrash = "issues-crash",
  IssuesError = "issues-error",
  Jira = "jira",
  JksFile = "jks-file",
  JsonFile = "json-file",
  Loading = "loading",
  Locked = "locked",
  Lock = "lock",
  LockAlt = "lock-alt",
  LockOpen = "lock-open",
  Log = "log",
  LogOut = "logout",
  Menu = "menu",
  Microsoft = "microsoft",
  MobileCenterLogo = "mobile-center-logo",
  MobileCenter = "mobile-center",
  MobileprovisionFile = "mobileprovision-file",
  More = "more",
  MoreFilled = "more-filled",
  NavigateBack = "navigate-back",
  NavigateNext = "navigate-next",
  None = "none",
  Note = "note",
  OneSignal = "one-signal",
  OpenInNew = "open-in-new",
  Organization = "organization",
  Package = "package",
  People = "people",
  PfxFile = "pfx-file",
  Phone = "phone",
  Preview = "preview",
  QuestionMark = "question-mark",
  QuestionMarkOutlinedLarge = "question-mark-outlined-large",
  QuestionMarkOutlinedSmall = "question-mark-outlined-small",
  Refresh = "refresh",
  Release = "release",
  ReleaseDisabled = "release-disabled",
  ReleaseDisabledDetails = "release-disabled-details",
  ReleaseEnableDetails = "release-enable-details",
  ReleaseExternal = "release-external",
  ReleaseExternalDetails = "release-external-details",
  ReleaseMandatory = "release-mandatory",
  Remove = "remove",
  RemoveCircle = "remove-circle",
  Repository = "repository",
  Satellite = "satellite",
  Search = "search",
  SelfHost = "self-host",
  Send = "send",
  Settings = "settings",
  Share = "share",
  SharedDistributionGroup = "shared-distribution-group",
  Shield = "shield",
  SignalFlag = "signal-flag",
  SignOut = "sign-out",
  SortDown = "sort-down",
  SortUp = "sort-up",
  Spinner = "spinner",
  Star = "star",
  StatusCancelled = "status-cancelled",
  StatusCommit = "status-commit",
  StatusCommitBuilt = "status-commit-built",
  StatusCommitCrashed = "status-commit-crashed",
  StatusCommitEmpty = "status-commit-empty",
  StatusCommitFailed = "status-commit-failed",
  StatusCommitNone = "status-commit-none",
  StatusCommitQueued = "status-commit-queued",
  StatusCommitRunning = "status-commit-running",
  StatusCrashed = "status-crashed",
  StatusCrashedOutline = "status-crashed-outline",
  StatusEmpty = "status-empty",
  StatusEmptyOutline = "status-empty-outline",
  StatusError = "status-error",
  StatusFailed = "status-failed",
  StatusFailedOutline = "status-failed-outline",
  StatusNone = "status-none",
  StatusNoneOutline = "status-none-outline",
  StatusPassed = "status-passed",
  StatusPassedOutline = "status-passed-outline",
  StatusQueued = "status-queued",
  StatusQueuedOutline = "status-queued-outline",
  StatusRunning = "status-running",
  StatusRunningOutline = "status-running-outline",
  Suitcase = "suitcase",
  Support = "support",
  TestTube = "test-tube",
  Time = "time",
  Transition = "transition",
  Translation = "translation",
  TrendingDown = "trending-down",
  TrendingUp = "trending-up",
  TrendingUnchanged = "trending-unchanged",
  Twitter = "twitter-box",
  TxtFile = "txt-file",
  Unlocked = "unlocked",
  Upload = "upload",
  User = "user",
  VideoPause = "video-pause",
  VideoPlay = "video-play",
  VideoStop = "video-stop",
  ViewGrid = "view-grid",
  ViewList = "view-list",
  AzureDevOps = "azure-dev-ops",
  Warning = "warning",
  WarningFilled = "warning-filled",
  WiFi = "wifi",
  XcodeprojFile = "xcodeproj-file",
  ZipFile = "zip-file",
  ZoomIn = "zoom-in",
  ZoomOut = "zoom-out",
}

export type StatusIconName =
  | IconName.StatusCancelled
  | IconName.StatusCrashed
  | IconName.StatusCrashedOutline
  | IconName.StatusEmpty
  | IconName.StatusEmptyOutline
  | IconName.StatusError
  | IconName.StatusFailed
  | IconName.StatusFailedOutline
  | IconName.StatusNone
  | IconName.StatusNoneOutline
  | IconName.StatusPassed
  | IconName.StatusPassedOutline
  | IconName.StatusQueued
  | IconName.StatusQueuedOutline
  | IconName.StatusRunning
  | IconName.StatusRunningOutline;

export enum StatusIconState {
  None,
  Queued,
  InProgress,
  Failed,
  Succeeded,
  Distributed,
  Canceled,
  Error,
}

/**
 *  Origins
 */
export type Origin = "mobile-center" | "appcenter" | "hockeyapp" | "codepush" | "testcloud";
export const Origin = {
  MobileCenter: "mobile-center" as Origin,
  AppCenter: "appcenter" as Origin,
  HockeyApp: "hockeyapp" as Origin,
  CodePush: "codepush" as Origin,
  TestCloud: "testcloud" as Origin,
};

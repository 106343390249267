/**
 * All environments of Portal
 */
module.exports = {
  DEV: "development",
  INT: "integration",
  PROD: "production",
  TEST: "test",
  STAGING: "staging",
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1dcMdFq2x{}", "",{"version":3,"sources":["webpack://client/shared/table/text-cell.scss"],"names":[],"mappings":"AAAA,YACE","sourcesContent":[".ellipsize {\n  composes: ellipsize from '../styles/utils.scss';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ellipsize": "_1dcMdFq2x " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["ellipsize"] + ""
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1gxxMTebx{margin:10px 0;flex:1 0 auto;overflow:hidden}", "",{"version":3,"sources":["webpack://client/shell/navigation/navigation.scss"],"names":[],"mappings":"AAAA,YACE,aAAA,CACA,aAAA,CACA,eAAA","sourcesContent":[".navigation {\n  margin: 10px 0;\n  flex: 1 0 auto;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "_1gxxMTebx"
};
export default ___CSS_LOADER_EXPORT___;

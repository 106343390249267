// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ZEswoE1x{display:flex;height:100%}", "",{"version":3,"sources":["webpack://client/shell/page-not-found/page-not-found.scss"],"names":[],"mappings":"AAAA,YACE,YAAA,CACA,WAAA","sourcesContent":[".container {\n  display: flex;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1ZEswoE1x"
};
export default ___CSS_LOADER_EXPORT___;
